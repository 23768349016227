<template>
	<div>
		<addUserV2 />
	</div>
</template>

<script>
import addUserV2 from '../insureV2/components/addUserV2.vue';
export default {
	components: {
		addUserV2,
	},
};
</script>

<style lang="scss" scoped></style>
